<template>
  <div id="blog">
    <div class="container-fluid">
      <div class="row">
        <div v-if="data.length === 0" class="col-md-9 text-center mt-3">
          <h3>BRAK WYNIKÓW</h3>
        </div>
        <div v-else class="col-md-9 row blog-post-container ml-1">
          <div class="col-sm-6 blogPosts" v-for="item in data" :key="item.id">
            <h3 class="title">{{ item.title }}</h3>
            <div class="row">
              <div class="col-2">
                <img class="authorPhoto" :src="item.authorImgPath" alt="" />
              </div>
              <div class="col-10">
                <h6>{{ item.date }}</h6>
              </div>
            </div>
            <!-- <img class="mainPhoto" :src="item.imgPath" alt="" /> -->
            <p v-html="item.shortDesc"></p>

            <router-link
              :to="'blog/' + item.id"
              class="text-uppercase read-more"
              ><h5>czytaj więcej</h5></router-link
            >
          </div>
          <div class="pagination" v-if="dataLength > 4">
            <button @click="prev()" class="prev" v-if="left">
              &larr;
            </button>
            <button @click="next()" class="next" v-if="right">
              &rarr;
            </button>
          </div>
        </div>

        <div class="col-md-3 ">
          <blog-router></blog-router>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Data from "../data/blog.data";
import BlogRouter from "../components/BlogRouter";
export default {
  name: "Blog",
  components: {
    BlogRouter,
  },
  data: function() {
    return {
      data: [],
      limit: 4,
      page: 0,
      left: false,
      right: true,
    };
  },
  watch: {
    $route() {
      this.reload();
    },
  },
  created() {
    this.reload();
  },
  computed: {
    dataLength() {
      return Data.length;
    },
  },
  methods: {
    reload() {
      let { month } = this.$route.query;
      month = parseInt(month);
      if (month && month >= 1 && month <= 12) {
        this.setMonth(month);
      } else {
        this.setData();
      }
    },
    prev() {
      if (this.page < 1) {
        this.left = false;
        this.right = true;
        return;
      } else {
        this.page = this.page - 1;
        this.right = true;
        if (this.page < 1) this.left = false;
        this.setData();
      }
    },
    next() {
      const maxPage = Data.length / this.limit;
      if (this.page >= maxPage) {
        this.right = false;
        return;
      } else {
        this.page = this.page + 1;
        this.left = true;
        if (this.page + 1 >= maxPage) this.right = false;
        this.setData();
      }
    },
    setData() {
      const { page, limit, compare } = this;
      const Page = page * limit;
      const Limit = (page + 1) * limit;
      this.data = Data.slice(Page, Limit).sort(compare);
    },
    compare(a, b) {
      if (a.date < b.date) {
        return 1;
      }
      if (a.date > b.date) {
        return -1;
      }
      return 0;
    },
    setMonth(month) {
      this.page = 0;
      const { page, limit } = this;
      this.data = Data.slice(page, limit).filter((item) => {
        return item.month == month;
      });
    },
  },
};
</script>
<style scoped>
.container-fluid {
  margin-top: 70px;
  max-width: 1450px;
}
a {
  color: black;
}
a:hover {
  text-decoration: none;
}

.title {
  margin: 14px 0;
}

.fullName {
  font-size: 1rem;
  margin: 0;
}

.authorPhoto {
  width: 40px;
  border-radius: 100px;
}

.mainPhoto {
  margin: 6px 0;
  width: 100%;
  object-fit: cover;
  height: 220px;
}
.pagination {
  position:absolute;
  bottom: -5%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.pagination .next,
.pagination .prev {
  background-color: transparent;
  border: 2px solid black;
  border-radius: 50px;
  height: 40px;
  width: 40px;
  font-size: 20px;
  padding: 2px;
  margin: 32px 3px;
}

.read-more h5 {
  text-align: right;
  font-size: 16px;
  text-decoration: underline;
}
</style>
